import React from 'react'
import ReactDOM from 'react-dom'
import 'css/index.css'
import App from './App'
import '@fontsource/roboto'
import "@fontsource/comfortaa"
import "@fontsource/maven-pro/500.css"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'


const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00c0c6",

      // main: "#FF4143",

      // main: "#E03C26",

      // main: "#FF512A",
    },
    secondary: {
      main: "#ff6010",
    },
    white: {
      main: "#FFFFFF",
    }
  },
  footerHeight: 72,
  mobileFooterHeight: 56,
  sidebarWidth: 300,
  sidebarMobileHeight: 90,
  sidebarMobilePadding: 8,
  participantBorderWidth: 2,
  mobileTopBarHeight: 52,
  chatWindowWidth: 320,
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
)

