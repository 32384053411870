import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import GitHubIcon from '@material-ui/icons/GitHub'
import DescriptionIcon from '@material-ui/icons/Description'
import IconButton from '@material-ui/core/IconButton'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import Snackbar from '@material-ui/core/Snackbar'
import Container from '@material-ui/core/Container'
import { Card, CardContent } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'


const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	blue: {
		backgroundColor: "#FFB749"
	},
	avatar: {
		width: theme.spacing(25),
		height: theme.spacing(25),
		marginTop: theme.spacing(7),
	},
	name: {
		marginTop: theme.spacing(2),
		fontFamily: 'Comfortaa',
		fontWeight: 700,
	},
	header: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
	about: {
		marginTop: theme.spacing(1),
	},
	icon: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		color: '#000000',
	},
	address: {
		float: 'left',
		color: '#000000',
		marginLeft: theme.spacing(1),
	},
	mindsmapIcon: {
		width: theme.spacing(10),
	},
	aladaeeIcon: {
		width: theme.spacing(20),
	},
	secureSoundIcon: {
		width: theme.spacing(15),
	},
	atag: {
		textDecoration: 'none',
		color: "#2F93E0",
	},
	linkText: {
		color: "#2F93E0",
		marginTop: theme.spacing(1),
	},
	aboutProj: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(2),
	},
	myContrib: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(2),
	},
	projectDtls: {
		marginBottom: theme.spacing(2),
	},
	projectCard: {
		backgroundColor: "#FAFAFA",
	}
}))

function App() {
	const classes = useStyles()
	const [open, setOpen] = useState(false)

	const openURL = (url) => {
		window.open(url, '_blank')
	}

	const copyURL = (url) => {
		navigator.clipboard.writeText(url)
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Grid container spacing={4}>
				<Grid item xs={12} className={classes.blue}>
					<div className="justifycenter" >
						<Avatar alt="Kaustav" src="/images/avatar2.jpg" className={classes.avatar} />
					</div>
					<div className="justifycenter" >
						<Typography variant="h4" className={classes.name} >Kaustav Banerjee</Typography>
					</div>
					<div className="justifycenter" >
						<Typography variant="h6" className={classes.about} >Software Engineer</Typography>
					</div>
					<div className="justifycenter" >
						<IconButton component="span" onClick={() => openURL('https://github.com/kaustavb12')} >
							<GitHubIcon className={classes.icon} />
						</IconButton>
						<IconButton component="span" >
							<DescriptionIcon className={classes.icon} onClick={() => openURL('/KaustavCV.pdf')} />
						</IconButton>
						<IconButton component="span" onClick={() => copyURL('reachout@kaustav.in')} >
							<AlternateEmailIcon className={classes.icon} />
							<Snackbar
								anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
								open={open}
								autoHideDuration={6000}
								onClose={handleClose}
								message="Email Copied!"
							/>
						</IconButton>
					</div>
				</Grid>
				<Grid item xs={12} >
					<div className="justifycenter" >
						<Typography variant="h5" className={classes.header} >Past Experiences</Typography>
						<Divider variant="middle" style={{ height: '2px' }} />
					</div>
				</Grid>
				<Grid item xs={12} >
					<Container maxWidth="md" >
						<Card className={classes.projectCard} >
							<Grid container spacing={4}>
								<Hidden smDown>
									<Grid item container xs={3} alignContent="center" justify="center">
										<img src="/images/mindsmap.png" alt="Mindsmap" className={classes.mindsmapIcon} />
									</Grid>
									<Grid item xs={9} className={classes.projectDtls}>
										<Typography variant="h4" className={classes.linkText} >
											<a href="https://mindsmap.com" target="_blank" rel="noreferrer" className={classes.atag} >
												Mindsmap
											</a>
										</Typography>
										<Typography variant="body2" color="textSecondary" className={classes.aboutProj}>Mindsmap is SaaS platform for Psychiatric and Mental Healthcare Clinics. Clinics can manage their scheduling/appointments, interact with their patients through video chat and text, manage prescriptions and administer psychometric tests. </Typography>
										<Typography variant="body1" className={classes.myContrib}>The UI, back-end services and CI/CD infrastructure was fully designed and developed by me.</Typography>
										<Typography variant="body1" className={classes.myContrib}>This project was a tremendous learning experience for me. This was the first project where I fully embraced the micro-services architectural concepts, with the testing and development completely automated.</Typography>
									</Grid>
								</Hidden>
								<Hidden mdUp>
									<Grid item container xs={12} alignContent="center" justify="center">
										<img src="/images/mindsmap.png" alt="Mindsmap" className={classes.mindsmapIcon} />
									</Grid>
									<Grid item container xs={12} className={classes.projectDtls} justify="center">
										<Typography variant="h4" className={classes.linkText} >
											<a href="https://mindsmap.com" target="_blank" rel="noreferrer" className={classes.atag} >
												Mindsmap
											</a>
										</Typography>
									</Grid>
									<Grid item container xs={12} className={classes.projectDtls}>
										<Typography variant="body2" color="textSecondary" className={classes.aboutProj} style={{ marginLeft: '16px', }} >Mindsmap is SaaS platform for Psychiatric and Mental Healthcare Clinics. Clinics can manage their scheduling/appointments, interact with their patients through video chat and text, manage prescriptions and administer psychometric tests. </Typography>
										<Typography variant="body1" className={classes.myContrib} style={{ marginLeft: '16px', }} >The UI, back-end services and CI/CD infrastructure was fully designed and developed by me.</Typography>
										<Typography variant="body1" className={classes.myContrib} style={{ marginLeft: '16px', }} >This project was a tremendous learning experience for me. This was the first project where I fully embraced the micro-services architectural concepts, with the testing and development completely automated.</Typography>
									</Grid>
								</Hidden>
							</Grid>
						</Card>
					</Container>
				</Grid>
				<Grid item xs={12} >
					<Container maxWidth="md" >
						<Card className={classes.projectCard} >
							<Grid container spacing={4}>
								<Hidden smDown>
									<Grid item container xs={3} alignContent="center" justify="center">
										<img src="/images/aladaeelogo.svg" alt="Aladaee" className={classes.aladaeeIcon} />
									</Grid>
									<Grid item xs={9} className={classes.projectDtls}>
										<Typography variant="h4" className={classes.linkText} >
											<a href="https://purpletech.in/" target="_blank" rel="noreferrer" className={classes.atag} >
												Aladaee
											</a>
										</Typography>
										<Typography variant="body2" color="textSecondary" className={classes.aboutProj}>Aladaee was an online psychometric and IQ testing platform by Purple. Mental health experts could leverage the no-code environment to intuitively design questions of any complex format. They could also arrange any number of these questions into test suites which could then be administered to patients. Instantly upon completion of each test session, the platform automatically evaluated and generated PDF reports for the patient to download. </Typography>
										<Typography variant="body1" className={classes.myContrib}>This project not only tested my development skills but also satisfied my itch for solving complex problems.</Typography>
										<Typography variant="body1" className={classes.myContrib}>The challenges for this project included providing simple controls for designing any number of complex question formats with each question having its own set of input validations. Any number of such questions had to be presented to the patients, while being extremely performative and responsive. All this despite being severely limited of hosting capacity due to budgetary constraints.</Typography>
									</Grid>
								</Hidden>
								<Hidden mdUp>
									<Grid item container xs={12} alignContent="center" justify="center">
										<img src="/images/aladaeelogo.svg" alt="Aladaee" className={classes.aladaeeIcon} />
									</Grid>
									<Grid item container xs={12} className={classes.projectDtls} justify="center">
										<Typography variant="h4" className={classes.linkText} >
											<a href="https://purpletech.in/" target="_blank" rel="noreferrer" className={classes.atag} >
												Aladaee
											</a>
										</Typography>
									</Grid>
									<Grid item container xs={12} className={classes.projectDtls}>
										<Typography variant="body2" color="textSecondary" className={classes.aboutProj} style={{ marginLeft: '16px', }} >Aladaee was an online psychometric and IQ testing platform by Purple. Mental health experts could leverage the no-code environment to intuitively design questions of any complex format. They could also arrange any number of these questions into test suites which could then be administered to patients. Instantly upon completion of each test session, the platform automatically evaluated and generated PDF reports for the patient to download. </Typography>
										<Typography variant="body1" className={classes.myContrib} style={{ marginLeft: '16px', }} >This project not only tested my development skills but also satisfied my itch for solving complex problems.</Typography>
										<Typography variant="body1" className={classes.myContrib} style={{ marginLeft: '16px', }} >The challenges for this project included providing simple controls for designing any number of complex question formats with each question having its own set of input validations. Any number of such questions had to be presented to the patients, while being extremely performative and responsive. All this despite being severely limited of hosting capacity due to budgetary constraints.</Typography>
									</Grid>
								</Hidden>
							</Grid>
						</Card>
					</Container>
				</Grid>
				<Grid item xs={12} >
					<Container maxWidth="md" >
						<Card className={classes.projectCard} >
							<Grid container spacing={4}>
								<Hidden smDown>
									<Grid item container xs={3} alignContent="center" justify="center">
										<img src="/images/secsoundlogo.svg" alt="SecureSound" className={classes.secureSoundIcon} />
									</Grid>
									<Grid item xs={9} className={classes.projectDtls}>
										<Typography variant="h4" className={classes.linkText} >
											<a href="https://purpletech.in/" target="_blank" rel="noreferrer" className={classes.atag} >
												SecureSound
											</a>
										</Typography>
										<Typography variant="body2" color="textSecondary" className={classes.aboutProj}>SecureSound was a long-term R&D research initiative by Purple to help curb the social menace of female feticide in India. Using AI, the project aimed to detect and block genitals of fetus from ultrasound images in real-time to prevent the radiologist/clinician from determining its gender.</Typography>
										<Typography variant="body2" color="textSecondary" className={classes.aboutProj}>Purple received the prestigious BIRAC-BIG grant from Dept. of Biotechnology, Govt. of India.</Typography>
										<Typography variant="body1" className={classes.myContrib}>I was responsible for designing and developing the entire automated data pipeline to enable training and testing of ML models.</Typography>
										<Typography variant="body1" className={classes.myContrib}>The data pipeline included, data collection from hospitals and diagnostic centers, data pre-processing, data labelling, and classification.</Typography>
									</Grid>
								</Hidden>
								<Hidden mdUp>
									<Grid item container xs={12} alignContent="center" justify="center">
										<img src="/images/secsoundlogo.svg" alt="SecureSound" className={classes.secureSoundIcon} />
									</Grid>
									<Grid item container xs={12} className={classes.projectDtls} justify="center">
										<Typography variant="h4" className={classes.linkText} >
											<a href="https://purpletech.in/" target="_blank" rel="noreferrer" className={classes.atag} >
												SecureSound
											</a>
										</Typography>
									</Grid>
									<Grid item container xs={12} className={classes.projectDtls}>
										<Typography variant="body2" color="textSecondary" className={classes.aboutProj} style={{ marginLeft: '16px', }} >SecureSound was a long-term R&D research initiative by Purple to help curb the social menace of female feticide in India. Using AI, the project aimed to detect and block genitals of fetus from ultrasound images in real-time to prevent the radiologist/clinician from determining its gender.</Typography>
										<Typography variant="body2" color="textSecondary" className={classes.aboutProj} style={{ marginLeft: '16px', }} >Purple received the prestigious BIRAC-BIG grant from Dept. of Biotechnology, Govt. of India.</Typography>
										<Typography variant="body1" className={classes.myContrib} style={{ marginLeft: '16px', }} >I was responsible for designing and developing the entire automated data pipeline to enable training and testing of ML models.</Typography>
										<Typography variant="body1" className={classes.myContrib} style={{ marginLeft: '16px', }} >The data pipeline included, data collection from hospitals and diagnostic centers, data pre-processing, data labelling, and classification.</Typography>
									</Grid>
								</Hidden>
							</Grid>
						</Card>
					</Container>
				</Grid>
				<Grid item xs={12} >
					<div className="justifycenter" >
						<Typography variant="h5" className={classes.header} >Patents</Typography>
						<Divider variant="middle" style={{ height: '2px' }} />
					</div>
				</Grid>
				<Grid item xs={12} >
					<Container maxWidth="md" >
						<Card className={classes.projectCard} >
							<CardContent>
								<Grid container spacing={0}>
									<Grid item container xs={12} alignContent="center" >
										<Typography variant="h6" >
											<b>Method and system for detecting emotions based on typing behaviour</b>
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="body1" color="textSecondary" >US20170161629A1 - <i>Granted</i></Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="body1" color="textSecondary" >1234/KOL/2015 - <i>Under Process</i></Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Container>
				</Grid>
				<Grid item xs={12} >
					<Container maxWidth="md" >
						<Card className={classes.projectCard} >
							<CardContent>
								<Grid container spacing={0}>
									<Grid item container xs={12} alignContent="center" >
										<Typography variant="h6" >
											<b>Pattern Recognition by Convolutional Neural Networks</b>
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="body1" color="textSecondary" >201831008797 - <i>Under Process</i></Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Container>
				</Grid>
				<Grid item xs={12} >
				</Grid>
			</Grid>
		</div>
	)
}

export default App